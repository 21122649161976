import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Image from "../../components/image"
import SEO from "../../components/seo"
import styles from "./../work.module.sass"


const ReFit = () => (
  <Layout>
    {/*---- Work Detail ----*/}
    <SEO title="Re-fit" />

    <article className={styles.workDetail}>
      <Image fileName={"re-fit/main"} />
      <header className={styles.workDetail_header}>
        <h1 className={styles.workDetail_title}>Re-fit</h1>
        <h2 className={styles.workDetail_subTitle}>The interactive projection of replicated environment responding to observer's own motion</h2>
        <ul className={styles.workDetail_additionalInfo}>
          <li>2019 / Team Project</li>
          <li>Role: Interaction Designer, Technologist</li>
          <li>Collaborators: Dalia Todary-Michael, Weichen Tang, Qiuyan Da, Jowin Foo and Kongpyung Moon</li>
          <li>Main Tools: Processing, Kinect, Projector</li>
          <li>Keywords: Interactive Art</li>
        </ul>
      </header>

      <section className={styles.workDetail_section}>
        <h3 className={styles.workDetail_section_title}>Overview</h3>
        <p>
          Re-fit is a responsive installation for all ages that invites interaction with a projected visualisation of a replicated environment. 
          Visitors are invited to identify the environment copy that most closely responds to their own motion.
        </p>
      </section>

      {/*-------- Images --------*/}
      <section className={styles.workDetail_section}>
        <div className={styles.workDetail_largeImage_wrapper}>
          <Image fileName={"re-fit/conceptSketch"}   />
          <p className={styles.workDetail_images_caption}>Concept sketch</p>
        </div>

        <div className={styles.workDetail_largeImage_wrapper}>
          <Image fileName={"re-fit/cut_01.jpg"}   />
        </div>

        <div className={styles.workDetail_largeImage_wrapper}>
          <Image fileName={"re-fit/cut_02.jpg"}  />
        </div>

        <div className={styles.workDetail_largeImage_wrapper}>
          <Image fileName={"re-fit/cut_03.jpg"}   />
        </div>

        <div className={styles.workDetail_largeImage_wrapper}>
          <Image fileName={"re-fit/cut_04.jpg"}   />
        </div>

        <div className={styles.workDetail_largeImage_wrapper}>
          <Image fileName={"re-fit/cut_05.jpg"}  />
        </div>
      </section>
    </article>
  </Layout>
)

export default ReFit
